.users-management, .admins-management {
  .yellow-button {
    background-color: #F47E29;
    height: 32px;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    color: white;
  }
  .default--button {
    height: 32px;
    width: 140px;
    padding: 5px 10px;
    border-radius: 10px;
    outline: none;
    border: none;
    color: white;
    text-align: center;
  }
  .green--button {
    background-color: #339063;
  }
  .gray--button {
    background-color: #8a8a8a;
  }
  .red--button {
    background-color: #ff3030;
  }
  .yellow--button {
    background-color: #e2e05f;
  }
  .blue--button {
    background-color: #3c97e0;
  }
  .input-small {
    width: 150px;
  }
  .input-search {
    border: 1px solid rgb(49, 49, 49);
    border-radius: 5px;
    padding-left: 0.5rem;
    outline: none;
    cursor: pointer;
  }
  .input-select {
    border: 1px solid rgb(49, 49, 49);
    border-radius: 5px;
    outline: none;
    display: block;
    height: 28px;
    width: 100%;
    padding: 0 5px;
  }
  .highlight-text {
    color: #1B4D94;
    font-weight: 700;
  }
  .link-reports {
    text-decoration: underline;
    color: #1B4D94;
  }
  .yellow-button, .phone-number {
    white-space: nowrap;
  }
  .user-management-popup {
    background-color: white;
    top: -70%;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .close-popup {
      right: 0;
      top: 0;
      border: none;
      outline: none;
      background-color: #fff;
    }
  }
}
.hightlight-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: rgb(247 247 247);
  border: 1px solid rgb(177, 177, 177);
}
